import React, { useEffect } from "react";
import dent3 from "../assets/dent3.jpg";
import gal15 from "../assets/gal15.jpg";
import gal16 from "../assets/gal16.jpg";
import { Link } from "react-router-dom";

export default function Welcome() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // Close the mobile menu on navigation
  }, []);

  return (
    <div className=" md:px-0 px-4 flex justify-center items-center ">
      <div className=" md:flex justify-between gap-16  md:w-10/12 w-full">
        <div className="  flex-1 flex flex-col md:gap-8  gap-4">
          <div className="  text-primary-450 md:text-3xl text-2xl font-semibold">
            Welcome To The Dental Home
          </div>
          <div className=" text-xl text-gray-600">
            Welcome to the Teeth Zone Dental Home—a place where your smile
            matters most. Our team is dedicated to providing top-notch care in a
            warm environment. Your comfort is our priority.
          </div>
          <div className=" text-[17px] text-gray-500">
            Teeth Zone Dental Home stands as a beacon of quality dental care,
            offering a haven where patients feel heard, understood, and valued.
            Our team believes in fostering genuine connections with each
            individual who walks through our doors, building trust and rapport
            that form the foundation of lasting oral health partnerships. From
            the moment you step into our inviting space, you'll experience a
            blend of professionalism and compassion that sets us apart. Whether
            you're seeking a routine check-up, cosmetic enhancement, or
            restorative treatment, we prioritize your comfort and satisfaction
            every step of the way.
          </div>
          <Link
            to="/about"
            className="  text-xl flex md:justify-start justify-center items-center bg-primary-450 hover:bg-primary-500 duration-500 md:w-max md:px-16 py-2 text-gray-100 rounded-xl "
          >
            Read More
          </Link>
        </div>
        <div className=" md:flex-1 md:mt-0 mt-8 flex flex-col md:gap-8  gap-7 ">
          <img
            className="  w-full h-56 rounded-md object-cover"
            src={dent3}
            alt=""
          />
          <div className=" md:flex gap-8 hover:*:animate-pulse duration-150  justify-between ">
            <img
              className=" md:w-80 border rounded-md  h-48 w-full object-cover cursor-pointer"
              src={gal15}
              alt=""
            />
            <img
              className=" border md:mt-0 mt-4 md:w-60  rounded-md  h-48 w-full object-cover cursor-pointer"
              src={gal16}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
