import "./App.css";
import Navabar from "./components/Navabar";
import Footer from "./components/Footer";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AllServices from "./pages/AllServices";
import CrownBridge from "./pages/CrownBridge";
import DentalEmergency from "./pages/DentalEmergency";
import DentalImplant from "./pages/DentalImplant";
import DentalSurgery from "./pages/DentalSurgery";
import Digitalteeth from "./pages/Digitalteeth";
import OralCheckup from "./pages/OralCheckup";
import Orthodontic from "./pages/Orthodontic";
import Restoration from "./pages/Restoration";
import RootCanal from "./pages/RootCanal";
import ScalingPolishing from "./pages/ScalingPolishing";
import About from "./pages/About";
import Executive from "./pages/Executive";
import Gallery from "./pages/Gallery";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Contact from "./pages/Contact";
import Message from "./components/Message";
import Krishna from "./pages/Krishna";
import Suraj from "./pages/Suraj";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className=" ">
      <BrowserRouter>
        <Navabar />
        <div className=" md:py-36 py-24 ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/allServices" element={<AllServices />} />
            <Route path="/crownBridge" element={<CrownBridge />} />
            <Route path="/dentalEmergency" element={<DentalEmergency />} />
            <Route path="/dentalImplant" element={<DentalImplant />} />
            <Route path="/dentalSurgery" element={<DentalSurgery />} />
            <Route path="/digitalteeth" element={<Digitalteeth />} />
            <Route path="/oralCheckup" element={<OralCheckup />} />
            <Route path="/orthodontic" element={<Orthodontic />} />
            <Route path="/restoration" element={<Restoration />} />
            <Route path="/rootCanal" element={<RootCanal />} />
            <Route path="/scalingPolishing" element={<ScalingPolishing />} />
            <Route path="/about" element={<About />} />
            <Route path="/executive" element={<Executive />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/krishna" element={<Krishna />} />
            <Route path="/suraj" element={<Suraj />} />
          </Routes>{" "}
        </div>
        <Message />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
