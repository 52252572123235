import React from "react";
import krishna from "../assets/krishna.jpg";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Krishna() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div className=" flex flex-col gap-2 justify-center items-center ">
          <img
            className=" border px-4 py-5 cursor-pointer w-80 h-96 rounded-2xl object-cover"
            src={krishna}
            alt=""
          />
          <p className="  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Dr. Krishna A . Chaudhary
          </p>
          <p className=" md:text-[19px] text-[18px] text-gray-700">
            (Nmc No : 30588)
          </p>
        </div>
        <div className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10">
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-450 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            Dr. Krishna A . Chaudhary
          </div>
          <div className=" text-[17px] text-gray-500">
            Dr. Krishna Awatar Chaudhary is registered dental practitioner
            Practicing at the "Teeth Zone Dental Home" Pvt.Ltd tokha-10,
            samakhushi,Ktm.
          </div>
          <div className=" text-[17px] text-gray-500">
            He Completed his bachelor of dental surgery (BDS) from V.C.M.S
            college of dental suirgery to tribhuwan university.
          </div>
          <p className=" text-[17px] text-gray-500">DMD . Emilio aguirald college Manila Philippines .</p>
          <p className=" text-[17px] text-gray-500">NHPC No  B-303 DH. kantipur school of Deentistry .</p>
          <div className=" flex flex-col gap-2">
            <p className="  text-primary-450 md:text-2xl text-xl font-semibold">
              Contact Details
            </p>
            <p>Phone Number :- +977 9860022606</p>
            <p>Email :- ackissna@gmail.com</p>
            <p className="  mb-3">Address :- Tokha-8 , CRS Tokha</p>
            <p className=" flex md:justify-start justify-center items-center bg-primary-450 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 9AM - 2PM
            </p>
          </div>
        </div>
        <Link
          to="/executive"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
