import React from "react";
import digital from "../assets/digital.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function Digitalteeth() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={digital}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Digital teeth X-Ray
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            In the realm of modern dentistry, the advent of digital X-ray
            technology has revolutionized the way dental professionals diagnose
            and treat oral health issues. Through the lens of digital imaging,
            the intricate landscape of the teeth and surrounding structures is
            illuminated with unparalleled clarity and precision. Gone are the
            days of cumbersome film development and storage; digital X-rays
            provide instantaneous results, minimizing patient discomfort and
            reducing radiation exposure. With advanced software algorithms,
            these digital images can be enhanced, manipulated, and zoomed in on,
            allowing dentists to detect even the most minute abnormalities or
            signs of decay. The transition to digital X-rays represents a
            pivotal leap forward in dental care, ushering in an era of
            efficiency, accuracy, and patient-centricity.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Digital teeth X-rays offer a multifaceted glimpse into the oral
            health landscape, revealing crucial details that may escape the
            naked eye. From detecting cavities hidden between teeth to assessing
            the integrity of dental restorations, these high-definition images
            empower dentists to formulate comprehensive treatment plans tailored
            to each patient's unique needs. Furthermore, digital X-rays
            facilitate seamless collaboration between dental professionals,
            enabling easy sharing of images for consultations or referrals. By
            harnessing the power of digital technology, dentistry transcends its
            traditional boundaries, delivering enhanced diagnostics and
            personalized care that prioritizes both oral health and overall
            well-being. In the digital age, the smile's radiance is not only
            preserved but also safeguarded through the transformative
            capabilities of digital teeth X-rays.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
