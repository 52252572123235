import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import dent3 from "../assets/dent3.jpg";
import dental7 from "../assets/dental9.jpg"
import dental10 from "../assets/dental10.jpg";
import dental11 from "../assets/dental11.jpg";

export default function HeroSection() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:w-10/12  w-full flex  items-center   ">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {items.map((item, index) => (
            <SwiperSlide>
              <div
                key={index}
                className=" grid md:grid-cols-2 md:gap-20 gap-5  md:pb-12 pb-5 "
              >
                <div className=" md:flex-1 flex justify-start items-start  flex-col md:gap-8 gap-4">
                  <h1 className=" curve-underline  text-primary-450 md:text-3xl text-2xl font-semibold">
                    {item.name}
                  </h1>
                  <h2 className=" text-[17px] text-gray-500">{item.desc}</h2>
                  <div className="  w-full  grid md:grid-cols-2 md:gap-8 gap-7 md:mt-12 mt-4">
                    <button className="  w-full bg-primary-500 hover:bg-primary-450 duration-500 text-gray-100 px-10 py-2 text-xl rounded-md">
                      Call :- {item.btn}
                    </button>
                    <button className=" w-full  bg-primary-500 hover:bg-primary-450 duration-500 text-gray-100 px-10 py-2 text-xl rounded-md">
                      Call :- {item.btn1}
                    </button>
                  </div>
                </div>
                <div className=" md:flex-1 cursor-pointer">
                  <img
                    className=" rounded-xl border border-gray-300  w-full h-96  cursor-pointer object-cover"
                    src={item.img}
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

const items = [
  {
    img: dent3,
    name: "Using innovative technogy .",
    desc: "Innovative technology is revolutionizing industries. From AI to blockchain and IoT, these advancements are enhancing healthcare, finance, transportation, and beyond. Telemedicine, blockchain in finance, AI-driven analytics, electric and autonomous vehicles, and IoT-enabled systems are just a few examples of how technology is reshaping our world.",
    btn: "9844375834",
    btn1: "9860022606",
  },
  {
    img: dental7,
    name: "Certified Dentist Doctors .",
    desc: "Certified dentist doctors undergo extensive training and education, culminating in rigorous exams to obtain their license. They provide comprehensive dental care, often specializing further through residency or continuing education. Committed to professionalism and ethics, they deliver high-quality oral health services to improve patients' well-being.",
    btn: "9844375834",
    btn1: "9860022606",
  },
  {
    img: dental10,
    name: "Guarantee Success of Treatment",
    desc: " Ensuring treatment success in dentistry involves a multifaceted approach. Dentists prioritize evidence-based practices, educate patients on expectations, and provide personalized care. While success is the goal, it's influenced by various factors, and continuous monitoring and adjustments are essential for optimal outcomes.",
    btn: "9844375834",
    btn1: "9860022606",
  },
  {
    img: dental11,
    name: "Accepted Insurance Card .",
    desc: " The accepted insurance card standard provides a uniform format for essential information like the policyholder's name, policy number, insurer details, and coverage dates. This standardization ensures efficient processing of claims and improves communication between providers, insurers, and patients.",
    btn: "9844375834",
    btn1: "9860022606",
  },
];
