import React from "react";
import oral from "../assets/oralcheckup.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function OralCheckup() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8 h-96 w-full rounded-md object-cover cursor-pointer "
            src={oral}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Oral Checkup
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            During an oral checkup, your dentist will thoroughly examine your
            teeth, gums, and mouth to ensure optimal dental health. The
            examination typically starts with a visual inspection, where the
            dentist looks for any signs of decay, cavities, or gum disease. They
            may also use specialized tools like a dental mirror and probe to
            assess the condition of your teeth and gums more closely.
            Additionally, X-rays may be taken to detect any issues that are not
            visible to the naked eye, such as hidden cavities or problems with
            the roots of your teeth. After the examination, your dentist will
            discuss their findings with you and recommend any necessary
            treatments or preventive measures to maintain your oral health.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Following the examination, your dentist will often perform a
            professional cleaning to remove plaque and tartar buildup from your
            teeth and gums. This process involves scaling, where the dentist
            uses special tools to scrape away hardened plaque and tartar from
            the surfaces of your teeth. They may also polish your teeth to
            remove surface stains and make them shine. Additionally, your
            dentist may provide guidance on proper oral hygiene practices, such
            as brushing and flossing techniques, and offer advice on diet and
            lifestyle habits that can affect your dental health. By attending
            regular oral checkups and cleanings, you can help prevent dental
            problems and maintain a healthy, beautiful smile for years to come.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
