import React from "react";
import scaling from "../assets/scaling.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function ScalingPolishing() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={scaling}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Scaling & Polishing
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Scaling and polishing are essential dental procedures that
            contribute to oral health maintenance and the prevention of dental
            diseases. Scaling involves the removal of plaque, tartar, and stains
            from the surfaces of teeth, particularly in areas that are difficult
            to reach through regular brushing and flossing. This process is
            typically performed using specialized dental instruments to gently
            scrape away buildup without causing damage to the tooth enamel. By
            eliminating these deposits, scaling helps to reduce the risk of gum
            disease, cavities, and bad breath, promoting overall oral hygiene.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Following scaling, polishing is carried out to smoothen and polish
            the tooth surfaces, making them less susceptible to plaque
            accumulation. This procedure typically involves the use of a
            rotating rubber cup or brush along with a polishing paste, which
            buffs the teeth to a smooth, shiny finish. Polishing not only
            enhances the appearance of teeth by removing surface stains and
            discoloration but also provides a clean surface that makes it easier
            to maintain oral hygiene at home. Together, scaling and polishing
            play a crucial role in keeping teeth and gums healthy, contributing
            to a confident smile and optimal oral well-being.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
