import React from "react";
import implant from "../assets/implant.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function DentalImplant() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={implant}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Dental Implant
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Dental implants offer a revolutionary solution for individuals
            seeking to restore their smile and oral function. These small
            titanium posts are surgically placed into the jawbone, serving as
            sturdy anchors for replacement teeth. What sets dental implants
            apart is their ability to mimic the natural structure of teeth,
            providing unparalleled stability and longevity. Once implanted, the
            bone fuses with the titanium, creating a robust foundation for the
            prosthetic tooth or teeth to be attached. This integration not only
            ensures a secure fit but also helps prevent bone loss in the jaw,
            maintaining facial structure and overall oral health. With proper
            care, dental implants can last a lifetime, offering patients renewed
            confidence and the freedom to eat, speak, and smile with ease.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Beyond the functional benefits, dental implants can significantly
            enhance one's quality of life. Unlike traditional dentures or
            bridges, implants eliminate the inconvenience of removable
            appliances, allowing individuals to enjoy their favorite foods
            without restrictions. Moreover, they look and feel like natural
            teeth, providing a seamless aesthetic appearance. Whether replacing
            a single tooth or securing a full arch of prosthetic teeth, dental
            implants offer a comprehensive solution that restores both the
            beauty and functionality of a complete smile. With advancements in
            implant technology and techniques, more patients than ever can
            benefit from this transformative dental procedure.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
