import React from "react";
import dental from "../assets/dent2.jpg";

import user from "../assets/user.png";

export default function About() {
  return (
    <div className="  md:px-0 px-4 flex  justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div className="  text-primary-450 md:text-3xl text-2xl font-semibold">
          About The Teeth Zone Dental
        </div>
        <div className=" text-xl text-gray-600">
          Welcome to the Teeth Zone Dental Home—a place where your smile matters
          most. Our team is dedicated to providing top-notch care in a warm
          environment. Your comfort is our priority.
        </div>
        <div className=" text-[17px] text-gray-500">
          The Teeth Zone Dental Home, nestled in the heart of Tokha-10 near
          Samakhushi Chowk, stands as a beacon of oral health care excellence.
          Renowned for its commitment to top-notch dental services, this clinic
          offers a comprehensive range of treatments in a warm and welcoming
          environment. From routine check-ups to advanced procedures, patients
          can expect personalized care tailored to their unique needs. The team
          of experienced dentists at Teeth Zone is dedicated to ensuring patient
          comfort and satisfaction, utilizing state-of-the-art equipment and
          techniques to deliver exceptional results. Whether it's a simple
          cleaning or a complex dental restoration, individuals can trust Teeth
          Zone Dental Home for compassionate and professional dental care.
        </div>

        <img className=" h-96 w-full object-cover rounded-md" src={dental} alt="" />
        <div className="   grid lg:grid-cols-2   items-center md:mt-16 mt-3">
          <div className=" flex flex-col md:gap-7  gap-4 ">
            <div className=" cursor-pointer  text-primary-450 md:text-3xl text-2xl font-semibold">
              Our Clinical Facts
            </div>
            <div className=" text-[17px] text-gray-500">
              "At our Clinical Services, we are dedicated to providing
              comprehensive and compassionate care to our patients. Our team of
              highly trained professionals works tirelessly to assess, diagnose,
              and treat a wide range of medical conditions, utilizing the latest
              advancements in healthcare technology and research. We prioritize
              patient safety and comfort, ensuring that each individual receives
              personalized attention and treatment tailored to their unique
              needs. With a focus on evidence-based practice and collaborative
              care, we strive to achieve optimal outcomes and improve the
              overall well-being of our patients."
            </div>
            <div className=" md:mt-9 mt-4  grid md:grid-cols-3 md:gap-10 gap-8">
              <div className=" items-center flex flex-col md:gap-2 gap-1">
                <p className="md:text-5xl text-4xl text-gray-700 cursor-pointer">
                  01
                </p>
                <h1 className=" text-primary-400 md:text-2xl  text-xl ">
                  Years Experience
                </h1>
              </div>
              <div className=" items-center flex flex-col md:gap-2 gap-1">
                <p className="md:text-5xl text-4xl text-gray-700  cursor-pointer">
                  400+
                </p>
                <h1 className=" text-primary-400 md:text-2xl text-xl  ">
                  Happy Patinets
                </h1>
              </div>
              <div className="  items-center flex flex-col md:gap-2 gap-1">
                <p className=" md:text-5xl text-4xl text-gray-700 cursor-pointer  ">
                  100%
                </p>
                <h1 className=" text-primary-400 md:text-2xl  text-xl ">
                  Satisfaction
                </h1>
              </div>
            </div>
          </div>
          <div className=" flex justify-end">
            <img
              className=" cursor-pointer object-cover md:flex  hidden"
              src={user}
              alt=""
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
