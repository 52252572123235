import React from "react";
import resto from "../assets/restoration.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function Restoration() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8 h-96 rounded-md w-full object-cover cursor-pointer "
            src={resto}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Restoration
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            In the realm of dental health, a Restoration refers to the
            meticulous process of repairing and renewing damaged or decayed
            teeth to restore both function and aesthetics. Utilizing advanced
            techniques and materials, skilled dental professionals embark on a
            journey to revitalize smiles and enhance oral well-being. Through
            careful assessment and personalized treatment plans, each
            Restoration endeavor aims to rejuvenate the natural beauty and
            functionality of teeth, ensuring patients regain confidence in their
            smiles and overall dental health.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            From the precise shaping of dental prosthetics to the artful
            application of modern bonding agents, every Restoration procedure
            embodies the marriage of science and artistry. With a focus on
            preserving as much natural tooth structure as possible, dental
            Restorations not only address immediate concerns but also promote
            long-term oral health. As patients witness the transformation of
            their smiles, they embark on a journey of dental rejuvenation,
            experiencing the joy of renewed confidence and the freedom to laugh,
            speak, and eat without hesitation.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
