import React from "react";
import ortho from "../assets/ortho.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function Orthodontic() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={ortho}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Orthodontic
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Orthodontic treatment is a specialized field dedicated to correcting
            dental misalignments and irregularities. Through the use of various
            tools and techniques, orthodontists tailor treatment plans to
            address each patient's unique needs. Traditional braces, composed of
            metal brackets and wires, remain a steadfast option for effectively
            straightening teeth. However, advancements in orthodontic technology
            have introduced more discreet alternatives such as ceramic braces
            and clear aligners, providing patients with greater comfort and
            aesthetic appeal during their treatment journey. Beyond cosmetic
            enhancements, orthodontic interventions contribute significantly to
            overall oral health by reducing the risk of dental decay, gum
            disease, and other issues associated with misaligned teeth and jaws.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Moreover, orthodontic care extends beyond mere aesthetics,
            facilitating improved chewing function, speech clarity, and
            long-term dental health. By aligning the teeth and jaws correctly,
            orthodontists not only enhance the appearance of their patients'
            smiles but also promote optimal oral function and well-being.
            Through personalized treatment plans and ongoing support,
            orthodontic professionals play a vital role in helping individuals
            achieve not only straighter teeth but also healthier mouths and
            greater confidence in their smiles.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
