import React from "react";
import krishna from "../assets/krishna.jpg";
import suraj from "../assets/suraj.png";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

export default function Executive() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-7 gap-5 justify-center md:items-center md:w-10/12 w-full">
        <div className="  text-primary-450 md:text-3xl text-2xl font-semibold">
          Executive Comittee
        </div>
        <div className=" text-[17px] md:text-[19px] text-gray-600">
          Leadership shaping vision, strategy, and organizational excellence
          with experienced guidance.
        </div>

        <div className="  mt-5 grid lg:grid-cols-2  md:gap-20  gap-8">
          {items.map((item, index) => (
            <Link to={item.path}>
              <Tilt
                key={index}
                className=" w-max cursor-pointer flex flex-col gap-3 justify-center items-center border py-5 px-10 rounded-xl"
              >
                <img
                  className="   md:h-80 md:w-80 w-72 h-72 object-cover  rounded-md"
                  src={item.img}
                  alt=""
                />
                <h1 className=" flex justify-center items-center border-b w-full pb-2 text-xl text-gray-700 font-semibold">
                  {item.Name}
                </h1>
                <p className=" md:text-[19px] text-[18px] text-gray-700">
                  {item.Position}
                </p>
              </Tilt>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

const items = [
  {
    img: krishna,
    Name: "Dr. Krishna A Chaudary",
    Position: "Director",
    path: "/krishna",
  },
  {
    img: suraj,
    Name: "Dr. Suraj Pandey",
    Position: "Director",
    path: "/suraj",
  },
];
