import React from "react";
import dent1 from "../assets/dent1.jpg";
import dent2 from "../assets/dent2.jpg";
import dent3 from "../assets/dent3.jpg";
import dent4 from "../assets/dent4.jpg";
import dent5 from "../assets/dent5.jpg";
import dent6 from "../assets/dent6.jpg";
import dent7 from "../assets/dent7.jpg";
import gal8 from "../assets/gal8.jpg";
import gal9 from "../assets/gal9.jpg";
import gal10 from "../assets/gal10.jpg";
import gal11 from "../assets/gal11.jpg";
import gal13 from "../assets/gal13.jpg";
import gal14 from "../assets/gal14.jpg";
import gal15 from "../assets/gal15.jpg";
import dental7 from "../assets/dental7.jpg";
import dental9 from "../assets/dental9.jpg";
import dental10 from "../assets/dental10.jpg";
import dental11 from "../assets/dental11.jpg";
import dental8 from "../assets/dental8.jpg";
import tg1 from "../assets/tg1.jpg";
import tg2 from "../assets/tg2.jpg";
import tg3 from "../assets/tg3.jpg";
import tg4 from "../assets/tg4.jpg";
import tg5 from "../assets/tg5.jpg";
import tg6 from "../assets/tg6.jpg";
import tg7 from "../assets/tg7.jpg";
import tg8 from "../assets/tg8.jpg";
import tg9 from "../assets/tg9.jpg";

export default function Gallery() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" flex  justify-center items-baseline md:w-10/12">
        <div className=" grid  lg:grid-cols-3 md:grid-cols-2 md:gap-12 gap-10">
          {images.map((image, index) => (
            <div key={index}>
              <img
                className=" cursor-pointer hover:translate-y-px duration-200 h-96 w-96 object-cover rounded-md"
                src={image.img}
                alt=""
              />
            </div>
          ))}
        </div>{" "}
      </div>
    </div>
  );
}

const images = [
  {
    img: dental9,
  },
  {
    img: dental10,
  },
  {
    img: dental11,
  },
  {
    img: tg1,
  },
  {
    img: tg2,
  },
  {
    img: tg3,
  },
  {
    img: tg4,
  },
  {
    img: tg5,
  },
  {
    img: tg6,
  },
  {
    img: tg7,
  },
  {
    img: tg8,
  },
  {
    img: tg9,
  },
  {
    img: dent2,
  },
  {
    img: dental7,
  },
  {
    img: gal9,
  },
  {
    img: gal10,
  },
  {
    img: dent3,
  },
  {
    img: gal8,
  },
  {
    img: dent1,
  },

  {
    img: gal10,
  },
  {
    img: gal11,
  },
  {
    img: dent4,
  },
  {
    img: dent5,
  },
  {
    img: dent6,
  },

  {
    img: gal13,
  },
  {
    img: gal14,
  },
  {
    img: gal15,
  },
  {
    img: dent7,
  },
  {
    img: dental8,
  },
];
