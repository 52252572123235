import React from "react";
import shot from "../assets/dent3.jpg";

export default function Demo() {
  const backgroundImageUrl = shot;

  return (
    <div className="bg-gray-100">
      {/* Full-screen section with fixed background image */}
      <div
        className="relative h-[60vh] bg-fixed bg-center bg-cover bg-no-repeat z-20"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="absolute inset-0   bg-black opacity-60"></div>{" "}
        {/* Overlay */}
        <div className="relative md:px-0 px-4 z-10 flex flex-col items-center justify-center h-full ">
          <h1 className="md:text-4xl text-3xl z-30 text-gray-200 font-bold tracking-widest italic cursor-pointer">
            Teeth Zone Dental: Where Excellence in Dentistry Reigns Supreme
          </h1>
          <p className="text-lg mt-6  text-gray-300 md:max-w-5xl z-30">
            Welcome to Teeth Zone Dental, where excellence in dentistry reigns
            supreme. Our dedicated team is committed to providing top-tier
            dental care in a warm and welcoming environment. From routine
            cleanings to advanced procedures, we prioritize your oral health and
            satisfaction above all else. Experience the difference with Teeth
            Zone Dental – where your smile is our passion.
          </p>
        </div>
      </div>
    </div>
  );
}
