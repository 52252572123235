import React from "react";
import crown from "../assets/Crowns.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function CrownBridge() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
           By Appointment
          </span>
          
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={crown}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Crown Bridge
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            A crown is a versatile dental restoration that encases a damaged or
            weakened tooth, restoring its strength, functionality, and
            aesthetics. Crafted from materials like porcelain, ceramic, or metal
            alloys, crowns are customized to match the color and shape of the
            natural teeth, ensuring a seamless blend with the rest of the smile.
            Whether protecting a tooth after a root canal treatment or restoring
            a severely decayed or fractured tooth, crowns provide durable and
            long-lasting solutions for patients seeking to preserve their dental
            health and enhance their smiles.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            A dental bridge is a fixed prosthetic device used to replace one or
            more missing teeth by bridging the gap between adjacent teeth.
            Consisting of one or more artificial teeth (pontics) anchored in
            place by dental crowns on either side, bridges restore proper
            chewing function, prevent the shifting of adjacent teeth, and help
            maintain the natural shape of the face. Dental bridges are
            meticulously crafted to match the color, size, and shape of the
            surrounding teeth, providing patients with a comfortable,
            functional, and aesthetically pleasing solution for restoring their
            smiles.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
