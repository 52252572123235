import React from "react";
import Welcome from "../components/Welcome";
import WhyChoose from "../components/WhyChoose";
import OurServices from "../components/OurServices";
import SuccessStory from "../components/SuccessStory";
import HeroSection from "../components/HeroSection";
import Demo from "../components/Demo";

export default function Home() {
  return (
    <div className=" flex flex-col md:gap-36 gap-20">
      <HeroSection />
      <Demo />
      <Welcome />

      <SuccessStory />
      <WhyChoose />

      <OurServices />
    </div>
  );
}
