import React from "react";
import tee from "../assets/tee.jpg";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";
import icon4 from "../assets/icon4.png";

export default function WhyChoose() {
  return (
    <div className=" flex justify-center items-center bg-primary-500 md:h-[68vh] h-full">
      <div className=" md:px-0 px-4 md:py-0 py-11 md:flex justify-between gap-16 items-center md:w-10/12 w-full">
        <div className=" md:flex-1">
          <img
            className="  md:h-[68vh]  md:rounded-none rounded-md w-full h-full md:pb-0 pb-5 object-cover cursor-pointer"
            src={tee}
            alt=""
          />
        </div>
        <div className=" md:flex-1 flex flex-col gap-5">
          <div className=" cursor-pointer text-gray-100 md:text-4xl text-3xl font-semibold tracking-widest">
            Why Choose Us
          </div>
          <div className=" text-gray-200 md:text-[19px] text-[16px]  ">
            Teeth Zone Dental Home stands out as a beacon of excellence in
            dental care. With a commitment to providing top-notch service and
            utilizing cutting-edge techniques, it has earned a stellar
            reputation as the go-to destination for all dental needs. The team
            of skilled professionals at Teeth Zone Dental Home not only
            possesses expertise in a wide range of dental procedures but also
            fosters a warm and welcoming environment for patients.
          </div>
          <div className=" grid md:grid-cols-2 md:gap-12 gap-8">
            {icons.map((icon, index) => (
              <div
                key={index}
                className=" cursor-pointer group flex items-center gap-2"
              >
                <img
                  className="   object-cover cursor-pointer md:h-14 md:w-14 h-12 w-12"
                  src={icon.img}
                  alt=""
                />
                <span className="  text-gray-300 md:text-2xl text-xl group-hover:text-gray-400 duration-200">
                  {icon.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const icons = [
  {
    img: icon1,
    name: "Accepted Insurance Card",
  },
  {
    img: icon2,
    name: "Using innovative technogy ",
  },
  {
    img: icon3,
    name: " Certified Dentist Doctors",
  },
  {
    img: icon4,
    name: "Guarantee Success of Treatment",
  },
];
