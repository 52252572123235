import React from "react";
import root from "../assets/rootcanal.webp";
import implant from "../assets/implant.webp";
import digital from "../assets/digital.webp";
import dental from "../assets/surgery.webp";
import scaling from "../assets/scaling.webp";
import denatlemr from "../assets/dentalemerjency.webp";
import { Link } from "react-router-dom";

export default function OurServices() {
  return (
    <div className="md:px-0 px-4  flex justify-center items-center">
      <div className="  flex flex-col gap-8 md:w-10/12 w-full ">
        <div className=" flex justify-between items-center">
          <h1 className=" cursor-pointer  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Our Clinical Services
          </h1>
          <a
            href="/allServices"
            className=" underline cursor-pointer hover:text-primary-350  duration-500"
          >
            View All Services
          </a>
        </div>
        <p className=" md:text-xl text-[16px] text-gray-600">
        "At our Clinical Services, we are dedicated to providing comprehensive and compassionate care to our patients. Our team of highly trained professionals works tirelessly to assess, diagnose, and treat a wide range of medical conditions, utilizing the latest advancements in healthcare technology and research. We prioritize patient safety and comfort, ensuring that each individual receives personalized attention and treatment tailored to their unique needs. With a focus on evidence-based practice and collaborative care, we strive to achieve optimal outcomes and improve the overall well-being of our patients."
        </p>
        <div className="card grid md:grid-cols-3 md:gap-14 gap-5 md:pr-0 pr-3">
          {services.map((service, index) => (
            <Link key={index} className=" group card1 w-full" to={service.path}>
              <img
                className=" h-14  w-14 rounded-md cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3">
                <p className="small  mt-3 text-gray-700 font-semibold  group-hover:text-gray-100">
                  {service.name}
                </p>
                <span className=" small md:text-[17px] text-[15px] text-gray-600 group-hover:text-gray-300">
                  {service.desc}
                </span>
                <span className=" text-primary-400 text-[15px] font-semibold group-hover:text-gray-300 group-hover:underline">
                  {service.more}
                </span>
              </div>
              <div className="go-corner" href="/">
                <div className="go-arrow text-3xl">→</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: root,
    name: "Root Canal Treatment (RCT)",
    desc: "Root Canal Treatment (RCT) is a crucial dental procedure aimed at salvaging teeth afflicted with severe infection or decay.",
    path: "/rootCanal",
    more: "Read More...",
  },
  {
    img: dental,
    name: "Dental Surgery",
    desc: "Dental surgery encompasses a spectrum of procedures aimed at addressing various oral health issues",
    path: "/dentalSurgery",
    more: "Read More...",
  },
  {
    img: implant,
    name: "Dental Implant",
    desc: "Dental implants offer a revolutionary solution for individuals seeking to restore their smile and oral function. ",
    path: "/dentalImplant",
    more: "Read More...",
  },
  {
    img: digital,
    name: "Digital teeth X-Ray",
    desc: "Digital teeth X-rays offer a multifaceted glimpse into the oral health landscape, revealing crucial details .",
    path: "/digitalteeth",
    more: "Read More...",
  },

  {
    img: scaling,
    name: "Scaling & Polishing",
    desc: "Scaling and polishing are essential dental procedures that contribute to oral health maintenance",
    path: "/scalingPolishing",
    more: "Read More....",
  },
  {
    img: denatlemr,
    name: "Dental Emergency",
    desc: "A dental emergency can strike unexpectedly, causing immense discomfort and disrupting daily routines.",
    path: "/dentalEmergency",
    more: "Read More...",
  },
];
