import React from "react";
import crown from "../assets/Crowns.webp";
import denatlemr from "../assets/dentalemerjency.webp";
import implant from "../assets/implant.webp";
import dental from "../assets/surgery.webp";
import digital from "../assets/digital.webp";
import oral from "../assets/oralcheckup.webp";
import ortho from "../assets/ortho.webp";
import resto from "../assets/restoration.webp";
import root from "../assets/rootcanal.webp";
import scaling from "../assets/scaling.webp";
import { Link } from "react-router-dom";

export default function AllServices() {
  return (
    <div className=" md:px-0 px-4 flex flex-col md:gap-10 gap-6 justify-center items-center ">
      <h1 className="  text-primary-450 md:text-2xl text-xl font-semibold underline ">
        All Our Services
      </h1>
      <span className=" md:px-36 md:text-[19px] text-[16px] text-gray-500">
        At Teeth Zone Dental Home, we offer a comprehensive range of dental
        services to meet all your oral health needs. Our services include
        routine dental exams and cleanings to maintain your dental health, as
        well as preventative treatments such as fluoride treatments and sealants
        to protect your teeth from decay. We also provide restorative procedures
        like fillings, crowns, bridges, and implants to repair damaged teeth and
        restore functionality. Our cosmetic dentistry options include teeth
        whitening, veneers, and Invisalign to enhance the appearance of your
        smile. Additionally, we offer treatments for gum disease, root canal
        therapy, and oral surgery for more complex dental issues.
      </span>
      <div className=" flex justify-center items-center md:w-10/12">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2  md:gap-14 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="  rounded-md group bg-primary-500 hover:bg-primary-450 duration-500"
            >
              <img
                className=" h-52  w-full cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3 px-5 py-6">
                <p className="  text-gray-100 md:text-2xl text-xl group-hover:text-gray-200 duration-200">
                  {service.name}
                </p>
                <span className=" text-gray-300 md:text-[19px] text-[16px]  group-hover:text-gray-300 ">
                  {service.desc}
                </span>
                <div className=" flex justify-between">
                  <span></span>
                  <Link
                    to={service.path}
                    className=" w-max bg-gray-100 px-4 py-1 rounded-md  hover:bg-gray-200 duration-500"
                  >
                    {service.btn}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: denatlemr,
    name: "Dental Emergency",
    desc: "A dental emergency can strike unexpectedly......",
    btn: "View Details",
    path: "/dentalEmergency",
  },
  {
    img: oral,
    name: "Oral Checkup",
    desc: "During an oral checkup, your dentist will thoroughly ......",
    btn: "View Details",
    path: "/oralCheckup",
  },
  {
    img: scaling,
    name: "Scaling & Polishing",
    desc: "Scaling and polishing are essential dental procedures......",
    btn: "View Details",
    path: "/scalingPolishing",
  },
  {
    img: resto,
    name: "Restoration",
    desc: " Restoration refers to the meticulous process of repairing......",
    btn: "View Details",
    path: "/restoration",
  },
  {
    img: root,
    name: "Root Canal Treatment (RCT)",
    desc: "Root Canal Treatment (RCT) is a crucial dental procedure ......",
    btn: "View Details",
    path: "/rootCanal",
  },
  {
    img: digital,
    name: "Digital teeth X-Ray",
    desc: "Digital teeth X-rays offer a multifaceted glimpse into the oral health......",
    btn: "View Details",
    path: "/digitalteeth",
  },
  {
    img: crown,
    name: "Crown Bridge",
    desc: "A crown is a versatile dental restoration that encases a damaged......",
    btn: "View Details",
    path: "/crownBridge",
  },
  {
    img: ortho,
    name: "Orthodontic",
    desc: "Orthodontic treatment is a specialized field dedicated to correcting dental......",
    btn: "View Details",
    path: "/orthodontic",
  },
  {
    img: implant,
    name: "Dental Implant",
    desc: "Dental implants offer a revolutionary solution for individuals seeking......",
    btn: "View Details",
    path: "/dentalImplant",
  },
  {
    img: dental,
    name: "Dental Surgery",
    desc: "Dental surgery encompasses a spectrum of procedures aimed......",
    btn: "View Details",
    path: "/dentalSurgery",
  },
];
