import React from "react";
import denatlemr from "../assets/dentalemerjency.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function DentalEmergency() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 w-full rounded-md object-cover cursor-pointer "
            src={denatlemr}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Dental Emergency
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            A dental emergency can strike unexpectedly, causing immense
            discomfort and disrupting daily routines. From severe toothaches to
            broken teeth or injuries to the mouth, these situations demand
            immediate attention to alleviate pain and prevent further
            complications. Acting swiftly is crucial in such cases, as delays
            can exacerbate the problem and lead to more extensive treatment
            requirements. Contacting a dentist or seeking urgent dental care is
            paramount, as professionals can provide prompt assessment and
            necessary intervention to address the underlying issue effectively.
            Whether it's managing pain, treating infections, or restoring
            damaged teeth, timely dental care is essential for restoring oral
            health and well-being.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            In addition to seeking professional help, there are steps one can
            take at home to manage a dental emergency temporarily. Rinse the
            mouth with warm water to clean the affected area, and use
            over-the-counter pain medication to alleviate discomfort. Applying a
            cold compress to the outside of the mouth can help reduce swelling
            and numb the area. However, these measures are temporary solutions,
            and it's crucial to seek professional dental care as soon as
            possible for comprehensive treatment and resolution of the
            emergency.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
