import React from "react";
import root from "../assets/rootcanal.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function RootCanal() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={root}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Root Canal Treatment (RCT)
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Root Canal Treatment (RCT) is a crucial dental procedure aimed at
            salvaging teeth afflicted with severe infection or decay. When the
            inner pulp of a tooth becomes infected due to deep decay or trauma,
            it can lead to excruciating pain and potential tooth loss if left
            untreated. During RCT, the dentist carefully removes the infected
            pulp, thoroughly cleanses the interior of the tooth, and then seals
            it with a biocompatible material to prevent further infection. This
            meticulous process not only alleviates pain but also preserves the
            natural tooth structure, enabling patients to maintain proper
            chewing function and aesthetics.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            While the reputation of root canals might evoke anxiety in some
            patients, modern advancements in dental technology and anesthesia
            have made the procedure relatively comfortable and highly effective.
            By undergoing RCT, patients can avoid the need for extraction and
            subsequent tooth replacement options, such as dental implants or
            bridges, which can be more invasive and costly. Ultimately, Root
            Canal Treatment offers a conservative yet impactful solution for
            preserving dental health and promoting overall well-being.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
