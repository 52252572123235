import React from "react";
import dental from "../assets/surgery.webp";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function DentalSurgery() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex gap-10 md:w-10/12 w-full">
        <div className=" flex flex-col gap-6  md:w-3/12">
        <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Opening Hours
          </span>
          <span className="text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Sunday - Friday</p>
            9:00 AM - 6.00 PM
          </span>
          <span className=" text-gray-600 border-b py-2 md:text-[19px] text-[16px]   flex justify-between items-center">
            <p>Saturday</p>
            By Appointment
          </span>
        </div>

        <div className=" md:w-7/12  flex flex-col md:gap-8 gap-6">
          <img
            className=" md:mt-0 mt-8  h-96 rounded-md w-full object-cover cursor-pointer "
            src={dental}
            alt=""
          />
          <span className="  text-primary-450 md:text-3xl text-2xl font-semibold ">
            Dental Surgery
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]  ">
            Dental surgery encompasses a spectrum of procedures aimed at
            addressing various oral health issues, ranging from routine
            extractions to complex reconstructive surgeries. One common
            procedure is wisdom teeth extraction, often necessitated by
            impaction or overcrowding in the mouth. Through precise surgical
            techniques and advanced anesthesia, dentists can safely remove these
            third molars, mitigating the risk of infection and preventing
            potential complications. Additionally, dental surgeons specialize in
            corrective jaw surgeries to rectify bite misalignments, addressing
            functional impairments and enhancing facial aesthetics. By employing
            cutting-edge technology such as 3D imaging and CAD/CAM systems,
            these procedures are tailored to each patient's unique anatomical
            needs, ensuring optimal outcomes and improved oral function.
          </span>
          <span className=" text-gray-600 md:text-[19px] text-[16px]">
            Furthermore, dental surgery plays a crucial role in the management
            of oral diseases such as periodontitis and oral cancers. Surgical
            interventions like gum grafting and flap surgery are employed to
            restore gum health and preserve the integrity of the
            tooth-supporting structures. In cases of oral cancer, surgical
            excision of tumors followed by reconstructive procedures helps
            restore both function and aesthetics, facilitating the patient's
            journey towards recovery. Collaborating closely with
            multidisciplinary healthcare teams, dental surgeons provide
            comprehensive care, emphasizing preventive measures and patient
            education to maintain long-term oral health and overall well-being.
          </span>
          <a
            href="/allServices"
            className=" flex gap-2 bg-primary-350 w-max px-8 py-1 text-gray-100 rounded-sm hover:bg-primary-300 duration-500  text-[17px] items-center"
          >
            <FaArrowLeftLong />
            <p>Back</p>
          </a>
        </div>
      </div>
    </div>
  );
}
